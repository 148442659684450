import './App.css';
import Navbar from './components/Navbar.js';
import Footer from './components/Footer.js';
import Home from './components/pages/Home.js';
import Blogs from './components/pages/Blogs.js';
import Courses from './components/pages/Courses.js';
import ContactUs from './components/pages/ContactUs.js';
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/contactus" element={<ContactUs />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
