import React from 'react';
import "./Footer.css";
import { FaYoutube } from "react-icons/fa6";
import { FaGithub } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";


const Footer = () => {
    const companyName = "<SalesforceCampus />";
    return (
        <>
            <footer className="footer">
                <div className="footer-content">
                    <p><span className='compName'>{companyName }</span> | &copy; 2024 salesforcecampus.com | All rights reserved.</p>
                    <div className="social-links">
                        <FaInstagram className='instagram' />
                        <FaYoutube className='youtube'/>
                        <FaGithub className='github'/>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer