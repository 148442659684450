import React, { useState, useEffect, useMemo, useRef } from 'react';
import "./Home.css";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { VscFeedback } from "react-icons/vsc";

const Home = () => {
    const topics = useMemo(
        () => [
            'Salesforce Admin',
            'Salesforce Development',
            'API Integration',
            'Lightning Web Components',
            'SOQL',
            'SOSL'
        ],
        []
    );

    const [currentWordIndex, setCurrentWordIndex] = useState(0);

    const displayedWord = useMemo(() => topics[currentWordIndex], [currentWordIndex, topics]);

    const [displayedWordStyle, setDisplayedWordStyle] = useState({
        opacity: 0,
        transition: 'opacity 0.8s ease-in-out' // Apply transition for opacity changes
    });

    useEffect(() => {
        let displayTimeout;

        const transitionWord = () => {
            setDisplayedWordStyle({ opacity: 1 }); // Fade in the word
            displayTimeout = setTimeout(() => {
                setDisplayedWordStyle((prevStyle) => ({ ...prevStyle, opacity: 0 })); // Fade out the word
                setTimeout(() => {
                    setCurrentWordIndex((prevIndex) => (prevIndex + 1) % topics.length);
                }, 500); // Wait for the transition to complete before changing the word
            }, 2000); // Hold for 2 seconds before transitioning to the next word

            return () => {
                clearTimeout(displayTimeout);
            };
        };

        transitionWord();

        return () => {
            clearTimeout(displayTimeout);
        };
    }, [currentWordIndex, topics]);

    // testimonials code
    const testimonials = [
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        // Add more testimonials as needed
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        // Add more testimonials as needed
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        // Add more testimonials as needed
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        // Add more testimonials as needed
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        { feedback: 'Great service!', author: 'John Doe', position: 'Salesforce Developer' },
        { feedback: 'Amazing product!', author: 'Jane Smith', position: 'Salesforce Developer' },
        // Add more testimonials as needed
    ];

    const sliderRef = useRef(null);

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    //   const next = () => {
    //     sliderRef.current.slickNext();
    //   };

    //   const previous = () => {
    //     sliderRef.current.slickPrev();
    //   };



    return (
        <>
            {/* Welcome Page DIV  */}
            <div className='mainDiv'>
                <div className='welcomeBox'>
                    <h1 className='text'>Welcome to <span className='welcomeHeading'>Salesforce Campus</span></h1>
                    <h2>
                        <span className='text2'>Learn </span>
                        <span style={displayedWordStyle} className='word-transition'>{displayedWord}</span>
                    </h2>
                    <p className='welcomeBlogs'>
                        Here, you'll dive into the expansive world of Salesforce. Whether you're new to the platform or a seasoned professional, this site offers a wealth of resources, tutorials, and insights tailored to elevate your Salesforce expertise. From mastering Salesforce Administration to exploring Development, honing API Integration skills, delving into Lightning Web Components (LWC), querying with SOQL, and unleashing the power of SOSL, our goal is to empower you with comprehensive learning experiences and practical knowledge. Explore our blogs, tutorials, and services designed to streamline your Salesforce journey. Get ready to unlock your full potential with Salesforce Campus.
                    </p>
                </div>
                <div className='RandomCodeBaseImage'>
                    <img src="/staticImages/Home.jpg" alt="" />
                </div>
            </div>

            {/* Recomended course div */}
            <div className="card-container">
                <h2>Recommended Courses</h2>
                <div className="card">
                    <img src="/staticImages/SF_Admin.jpg" alt="Salesforce Admin" />
                    <h2>Salesforce Admin</h2>
                    <p>Salesforce Admin training offers an immersive journey into configuring and managing Salesforce solutions. Through hands-on modules, it covers user controls, data handling, and customization techniques. This comprehensive course empowers individuals to streamline workflows and harness the full potential of Salesforce's features</p>
                    <button>Start Watching</button>
                </div>
                <div className="card">
                    <img src="/staticImages/SF_Dev.png" alt="Salesforce Developer" />
                    <h2>Salesforce Developer</h2>
                    <p>Salesforce Developer training unveils the art of building and customizing applications on the Salesforce platform. Exploring coding languages like Apex and Lightning components, this course empowers developers to create tailored solutions, integrate third-party tools, and elevate user experiences. Through practical modules, individuals master data modeling, business logic implementation, and cutting-edge development techniques.</p>
                    <button>Start Watching</button>
                </div>
                <div className="card">
                    <img src="/staticImages/LWC.png" alt="Lightning Web Component" />
                    <h2>Lightning Web Component</h2>
                    <p>Learning Lightning Web Components (LWC) immerses developers in the modern approach to building lightning-fast, interactive user interfaces on the Salesforce platform. This specialized training covers the essentials of component-based architecture, JavaScript, and Salesforce APIs, enabling developers to create responsive and scalable web applications. Through hands-on modules, individuals master the art of developing efficient, reusable components for diverse business needs.</p>
                    <button>Start Watching</button>
                </div>
            </div>

            {/* Testimonial */}
            <div className="testimonial-container">
                <h2>Testimonials</h2>
                <div className="testimonial-slider">
                    <Slider ref={sliderRef} {...settings}>
                        {testimonials.map((testimonial, index) => (
                            <div key={index}>
                                <div className="testimonial-item">
                                    <VscFeedback />
                                    {/* Display your testimonial content */}
                                    <p>{testimonial.feedback}</p>
                                    <br></br>
                                    <p>- {testimonial.author}</p>
                                    <p>{testimonial.position}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        </>
    );
};

export default Home;
