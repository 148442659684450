import React, { useState }  from 'react';
import "./ContactUs.css";

const ContactUs = () => {
    const [activeTab, setActiveTab] = useState('contact'); // 'contact' or 'feedback'
  
    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };
  
  return (
    <>
      <div className='container'>
        <h1>Contact Us</h1><br></br>
        <p>Questions, feedback -- We are here for it all.</p>
      </div>
      <div className='contact-us-form'>
      <div className='tab-container'>
      <div
          className={`tab ${activeTab === 'contact' ? 'active' : ''}`}
          onClick={() => handleTabClick('contact')}
        >
          Contact Us
        </div>
        <div
          className={`tab ${activeTab === 'reviews' ? 'active' : ''}`}
          onClick={() => handleTabClick('reviews')}
        >
          Reviews
        </div>
      </div>

      <div className='form-container'>
        <form className={`form ${activeTab === 'contact' ? 'active' : ''}`}>
          <input type='text' placeholder='Name' />
          <input type='email' placeholder='Email' />
          <input type='number' placeholder='Mobile Number' />
          <textarea placeholder='Message'></textarea>
          <button>Save</button>
        </form>
        
        <form className={`form ${activeTab === 'reviews' ? 'active' : ''}`}>
          <input type='text' placeholder='Name' />
          <input type='text' placeholder='Designation' />
          <input type='number' placeholder='Mobile Number' />
          <textarea placeholder='Reviews'></textarea>
          <button>Save</button>
        </form>
      </div>
      </div>
    </>
  )
}

export default ContactUs;
